import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import * as S from './Footer.style';

interface IFooterLink {
  path: string;
  text: string;
}

interface IFooter {
  footerLinks?: IFooterLink[];
}

export default function Footer({ footerLinks = [] }: IFooter) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const element = document.querySelector('meta[name="version-build"]');
  // const tagVersion = element instanceof HTMLMetaElement ? element.content : '';
  return (
    <S.StyledGrid container>
      <Grid item>
        {footerLinks.map(({ path, text }: IFooterLink, index) => (
          <S.StyledLink target={'_blank'} href={path} key={`footerLink_${index}`}>
            {text}
          </S.StyledLink>
        ))}
        <S.AboutButton onClick={handleClickOpen}>About</S.AboutButton>
        <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{'About'}</DialogTitle>
          <DialogContent>
            <DialogContentText>Version: {process.env.REACT_APP_VERSION}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <S.StyledButton onClick={handleClose} color="primary">
              Close
            </S.StyledButton>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item>
        <S.CopyRightText gutterBottom>
          {String.fromCharCode(169)} Amazon Web Services, Inc. or its affiliates. All rights
          reserved. OSDU is a trademark of The Open Group.
        </S.CopyRightText>
      </Grid>
    </S.StyledGrid>
  );
}
