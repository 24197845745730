import styled from 'layout/theme/styled';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.dark,
  paddingRight: 0,
  paddingLeft: 0,
  borderBottom: `${theme.height.topBarBorder}px solid ${theme.palette.primary.main}`,
}));

export const BackgroundImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
});

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: '0 !important',
  height: theme.height.topBar,
  zIndex: 1,
  justifyContent: 'space-between',
}));

export const LogoLink = styled(Link)({
  width: '300px',
  height: '80px',
  display: 'block',
  padding: '0',
  textAlign: 'center',
});

export const Logo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

export const Container = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const Centered = styled('div')({
  alignSelf: 'center',
  display: 'flex',
});

export const RightContainer = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

export const WorkloadMenuContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

export const HelpMenuContainer = styled(Centered)({});

export const UserMenuContainer = styled(Centered)({
  marginLeft: '32px',
});
