import HOME from './Home/Home';
import MANAGEMENT_CONSOLE from './DataConsole/DataConsole';
import EXPLORE_CURRENT_IMPLEMENTATION from './ExploreCurrentImplementation/ExploreCurrentImplementation';
import IN_PRODUCTION from './InProduction/InProduction';
import MEET_FUTURE_NEEDS from './MeetFutureNeeds/MeetFutureNeeds';
import ENERGEY_DATA_INSIGHTS from './EnergyDataInsights/EnergyDataInsights';
import SAMPLE_APP from './sample-app';
import SETTINGS_MODULE from './Settings/Settings';
import MONITORING_MODULE from './Monitoring/Monitoring';
import { IPageInformation } from './Pages.d';
import MapViewerPage from './MapViewerPage';

const appPages = [
  ...HOME,
  ...SETTINGS_MODULE,
  ...MANAGEMENT_CONSOLE,
  ...SAMPLE_APP,
  ...EXPLORE_CURRENT_IMPLEMENTATION,
  ...IN_PRODUCTION,
  ...MEET_FUTURE_NEEDS,
  MapViewerPage,
  ...ENERGEY_DATA_INSIGHTS,
];

if (String(process.env.REACT_APP_IS_MONITORING_ENABLED) === 'enabled') {
  appPages.push(...MONITORING_MODULE);
}

const pages: IPageInformation[] = [
    ...appPages
];

export default pages;
