import { lazy } from 'react';
const Cards = lazy(() => import('components/Cards/Cards'));
import SvgMenuHome from 'layout/assets/Icons/MenuHome';
import ImageOne from 'layout/assets/Images/platform_desktop-min.jpg';
import ImageTwo from 'layout/assets/Images/platform_blue_desktop-min.jpg';
import ImageThree from 'layout/assets/Images/sampleApp.jpg';
import ImageFour from 'layout/assets/Images/solar_desktop-min.jpg';
import ImageFive from 'layout/assets/Images/platform_3_desktop-min.jpg';
import ImageSix from 'layout/assets/Images/dataConsole.jpg';
import { IPageInformation } from 'pages/Pages.d';

const TITLE = 'Home';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));

const ITEMS: IPageInformation[] = [
  {
    title: 'Explore Current Implementation',
    description: (
      <>
        Understand and explore what&apos;s included in your deployment of Energy Data Insights on
        AWS.
      </>
    ),
    image: <img src={ImageFive} alt="Explore Current Implementation" />,
    path: '/ExploreCurrentImplementation',
  },
  {
    title: 'Meet Future Needs',
    description: (
      <>
        Understand OSDU&reg; Data Platform basics to set up users, ingest your own data, and explore
        benefits for your use cases.
      </>
    ),
    image: <img src={ImageTwo} alt="Meet Future Needs" />,
    path: '/MeetFutureNeeds',
  },
  {
    title: 'Production Operations',
    description: (
      <>
        Learn about considerations for using the OSDU Data Platform in an enterprise production
        operations setting.
      </>
    ),
    image: <img src={ImageThree} alt="Production Operations" />,
    path: '/InProduction',
  },
  {
    title: 'Data Console',
    description: (
      <>
        Interact with OSDU Data Platform services using a console user interface without writing any
        code.
      </>
    ),
    image: <img src={ImageFour} alt="Data Console" />,
    path: '/DataConsole',
  },
  {
    title: 'Sample Application',
    description: (
      <>
        Use the Sample Visualization Application published by the OSDU Forum to visualize wells in a
        3D space.
      </>
    ),
    image: <img src={ImageOne} alt="Sample Application" />,
    path: '/OsduVisualizationApp',
  },
  {
    title: 'Map Viewer',
    description: <>Map Viewer for wells</>,
    image: <img src={ImageSix} alt="Map Viewer" />,
    path: '/MapViewer',
  },
];

if (String(process.env.REACT_APP_IS_MONITORING_ENABLED) === 'enabled') {
  ITEMS.push( {
    title: 'Monitoring',
    description: (
      <>
        Monitoring Dashboard
      </>
    ),
    image: <img src={ImageOne} alt="Map Viewer" />,
    path: '/Monitoring',
  });
}

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => <Cards items={ITEMS} />,
    addToSideMenu: true,
    icon: <SvgMenuHome />,
  },
  ...ITEMS.filter((i) => i.component).map((i) => {
    return {
      path: i.path,
      component: i.component,
    };
  }),
];

export default pages;
