import { lazy } from 'react';
import MonitorHeart from '@mui/icons-material/MonitorHeart';
import { IPageInformation } from '../Pages';
const MonitoringContainer = lazy(() => import('./Components/MonitoringContainer/MonitoringContainer'));

const TITLE = 'Monitoring';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => <MonitoringContainer refresh={true} />,
    addToSideMenu: true,
    icon: <MonitorHeart />,
  },
];

export default pages;