import { lazy } from 'react';
import ExploreIcon from '@mui/icons-material/Explore';
const Services = lazy(() => import('./Components/Services'));
const OSDUTestDataSet = lazy(() => import('./Components/OSDUTestDataSet/OSDUTestDataSet'));
const AWSIntegration = lazy(() => import('./Components/AWSIntegration'));
const Endpoints = lazy(() => import('./Components/Endpoints'));
const OSDUTutorialsSampleApplication = lazy(
  () => import('./Components/OSDUTutorialsSampleApplication')
);
const CustomSwaggerPage = lazy(() => import('pages/CustomSwaggerPage/CustomSwaggerPage'));
import legalTagsSwagger from 'assets/legal_tags_swagger';
import entitlementsSwagger from 'assets/entitlements_swagger';
const Cards = lazy(() => import('components/Cards/Cards'));
import { Link } from 'react-router-dom';
import { IPageInformation } from '../Pages.d';

const TITLE = 'Explore Current Implementation';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = (
  <>
    To enable you to explore OSDU®
    <Link className="text-link" to="/Services">
      {' '}
      services{' '}
    </Link>
    and
    <Link className="text-link" to="/OSDUTestDataSet">
      {' '}
      data{' '}
    </Link>
    provided out-of-the-box, your deployment of Energy Data Insights on AWS provides the data
    platform APIs together with pre-loaded sample datasets and the tutorial
    <Link className="text-link" to="/OsduVisualizationApp">
      {' '}
      sample application{' '}
    </Link>
    published by the OSDU Forum. This makes it easy for you to get started right away to interact
    with and explore the code, data, APIs, and typical application usage. From this deployment
    portal you can access a
    <Link className="text-link" to="/DataConsole">
      {' '}
      management console{' '}
    </Link>
    user interface to selected data platform services. You can also explore material that explains
    key concepts of the OSDU Data Platform and how to leverage the platform in a production
    operations environment. Finally, you can learn about how your deployment is built on top of
    <Link className="text-link" to="/AWSIntegration">
      {' '}
      AWS{' '}
    </Link>
    and which underlying services are used.
  </>
);

const ITEMS: IPageInformation[] = [
  {
    title: 'Data Platform Services Overview',
    description: (
      <>
        Learn about the OSDU Data Platform services that are part of the OSDU standard, each of
        which are included in your deployment.
      </>
    ),
    path: '/Services',
    component: Services,
  },
  {
    title: 'Test Data Sets',
    description: (
      <>
        Learn about the Wells and Seismic domain test datasets published by the OSDU Forum and
        included in your deployment.
      </>
    ),

    path: '/OSDUTestDataSet',
    component: OSDUTestDataSet,
  },
  {
    title: 'Your OSDU Service Endpoints',
    description: (
      <>
        Learn how and where to access each of the OSDU Data Platform service endpoints included in
        your deployment of Energy Data Insights on AWS.
      </>
    ),
    path: '/Endpoints',
    component: Endpoints,
  },
  {
    title: 'Data Console',
    description: (
      <>
        Exercise data platform service endpoints through a console user interface without writing
        any code.
      </>
    ),
    path: '/DataConsole',
  },
  {
    title: 'OSDU® Tutorials Sample Application',
    description: (
      <>
        Learn about the sample application made available by the OSDU Forum and included in your
        deployment that demonstrates OSDU Data Platform services acting on test datasets.
      </>
    ),
    path: '/OSDUTutorialsSampleApplication',
    component: OSDUTutorialsSampleApplication,
  },
  /* Commented as part of ADOPTCAT Review  {
    title: 'Data Platform Dashboards',
    description: (
      <>
        Learn how and where to access OSDU Workload CloudWatch Dashboard included in your deployment
        of Energy Data Insights on AWS.
      </>
    ),
    path: '/Dashboard',
    component: Dashboard,
  },*/
  {
    title: 'AWS Integration',
    description: (
      <>
        Learn about how your deployment uses AWS services to support deployment, implementation, and
        operations of the included OSDU Data Platform services.
      </>
    ),
    path: '/AWSIntegration',
    component: AWSIntegration,
  },
];

const hiddenFromCards = [
  {
    component: () => <CustomSwaggerPage {...legalTagsSwagger} />,
    path: '/Endpoints/legalTags',
  },
  {
    component: () => <CustomSwaggerPage {...entitlementsSwagger} />,
    path: '/Endpoints/entitlements',
  },
];

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => <Cards header={{ title: TITLE, description: DESCRIPTION }} items={ITEMS} />,
    addToSideMenu: false,
    icon: <ExploreIcon />,
  },
  ...ITEMS.filter((i) => i.component).map((i) => {
    return {
      path: i.path,
      component: i.component,
    };
  }),
  ...hiddenFromCards,
];

export default pages;
