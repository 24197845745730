import { ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import LogoWhite from '../../assets/Images/logo_aws-lockup.png';
import { useAuthenticator } from '@aws-amplify/ui-react';
import * as S from './TopBar.style';

interface ITopBar {
  onSidebarOpen?: () => void;
  serviceMenu?: ReactNode;
  workloadMenu?: ReactNode;
  userMenu?: ReactNode;
  menuIcon?: ReactNode;
  toggleSideBar?: () => void;
  helpMenu?: ReactNode;
}

export default function TopBar({
  serviceMenu,
  workloadMenu,
  userMenu,
  menuIcon,
  toggleSideBar,
  helpMenu,
}: ITopBar) {
  const { user } = useAuthenticator((context) => [context.user]);
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  return (
    <S.StyledAppBar id="appBar">
      <S.StyledToolbar>
        <S.Container>
          {toggleSideBar && !isDesktop && (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={toggleSideBar}
              color="inherit"
              size="large"
            >
              {menuIcon}
            </IconButton>
          )}
          <S.LogoLink to="/Home">
            <S.Logo src={LogoWhite} alt={'47Lining logo'} />
          </S.LogoLink>
        </S.Container>
        {user && (
          <S.Container>
            {serviceMenu && isDesktop && <S.Centered>{serviceMenu}</S.Centered>}
            {(workloadMenu || helpMenu || userMenu) && isDesktop && (
              <S.RightContainer>
                <S.WorkloadMenuContainer>{workloadMenu}</S.WorkloadMenuContainer>
                <S.HelpMenuContainer>{helpMenu}</S.HelpMenuContainer>
                <S.UserMenuContainer>{userMenu}</S.UserMenuContainer>
              </S.RightContainer>
            )}
          </S.Container>
        )}
      </S.StyledToolbar>
    </S.StyledAppBar>
  );
}
